import { Link } from '@remix-run/react';
import React, { type ReactNode, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import { $path } from 'remix-routes';

import {
  EnumsGamePackChangeLevel,
  EnumsGamePackVersion,
} from '@lp-lib/api-service-client/public';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { useCopyTextLink } from '../../hooks/useCopyTextLink';
import { useMetaKeyHeld } from '../../hooks/useModKeyHeld';
import { useGamePackContext } from '../../pages/GamePack/Context';
import { apiService } from '../../services/api-service';
import { SiteBaseURL } from '../../services/public';
import {
  type GameLikeActionSheetSettings,
  type GamePack,
  GamePackActionType,
} from '../../types/game';
import { NotificationType } from '../../types/notification';
import { RoleUtils } from '../../types/user';
import {
  fromDTOGamePack,
  toGamePackPromotionalAssetsDTO,
} from '../../utils/api-dto';
import { downloadObjectAsJSON, uuidv4 } from '../../utils/common';
import { DateUtils } from '../../utils/date';
import { slugify } from '../../utils/router';
import { CalendarIcon } from '../icons/CalendarIcon';
import { CopyIcon } from '../icons/CopyIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { DownloadIcon } from '../icons/DownloadIcon';
import { DuplicateIcon } from '../icons/DuplicateIcon';
import { EditIcon } from '../icons/EditIcon';
import { ExitIcon } from '../icons/ExitIcon';
import { EyeIcon } from '../icons/EyeIcon';
import { HostIcon } from '../icons/HostIcon';
import { OptionsIcon } from '../icons/OptionsIcon';
import { PlayIcon } from '../icons/PlayIcon';
import { ShareIcon } from '../icons/ShareIcon';
import { ToolsIcon } from '../icons/ToolsIcon';
import { Loading } from '../Loading';
import { useNotificationDataSource } from '../Notification/Context';
import { useTrainingShareModal } from '../Training/Editor/TrainingShareModal';
import { useUser } from '../UserContext';
import { useGameLikeEventEmitter, useGameLikeWorkspace } from './GameCenter';
import { useOpenRoutedGamePackEditor } from './GamePack';
import { useTriggerGamePackPromotionalAssetsModal } from './GamePack/GamePackPromotionalAssets';
import { useTriggerTransferOwnershipModal } from './GamePack/TransferOwnershipModal';
import { GamePackUtils } from './GamePack/utils';

interface GamePackActionSheetProps {
  pack: GamePack;
  disabled?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  stopPropagation?: boolean;
  buttonClassName?: string;
  portal?: boolean;
}

const itemMenuStyle =
  'btn w-full h-8 px-2 hover:bg-dark-gray border-0 rounded-lg flex flex-row justify-start items-center gap-2 text-3xs';

function ActionSheetTooltip(props: {
  portal?: boolean;
  children: React.ReactNode;
}) {
  if (props.portal) {
    return createPortal(<>{props.children}</>, document.body);
  } else {
    return <>{props.children}</>;
  }
}

export const GamePackActionSheet = (
  props: GamePackActionSheetProps & {
    settings?: GameLikeActionSheetSettings;
  }
): JSX.Element => {
  const options = props;
  const [, setEditingGamePack] = useGameLikeWorkspace('gamePack', 'edit');
  const [, setDeletingGamePack] = useGameLikeWorkspace('gamePack', 'delete');
  const [, setPublishingGamePack] = useGameLikeWorkspace('gamePack', 'publish');
  const [, setDuplicatingGamePack] = useGameLikeWorkspace(
    'gamePack',
    'duplicate'
  );
  const [, setPreviewGamePack] = useGameLikeWorkspace('gamePack', 'preview');
  const notificationDataSource = useNotificationDataSource();
  const emitter = useGameLikeEventEmitter('gamePack');
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user);
  const triggerPromotionalAssetsModal =
    useTriggerGamePackPromotionalAssetsModal();
  const ctx = useGamePackContext();
  const triggerTransferOwnershipModal = useTriggerTransferOwnershipModal();

  const {
    state: { transformed: duplcateGamePackState },
    call: duplcateGamePack,
  } = useAsyncCall(
    useCallback(async (packId: string) => {
      return (await apiService.gamePack.duplicate(packId, { fork: false })).data
        .gamePack;
    }, [])
  );

  const {
    state: { transformed: forkGamePackState },
    call: forkGamePack,
  } = useAsyncCall(
    useCallback(async (packId: string) => {
      return (
        await apiService.gamePack.duplicate(packId, {
          fork: true,
        })
      ).data.gamePack;
    }, [])
  );

  const openGamePackEditor = useOpenRoutedGamePackEditor();
  const onEditingGamePack = () => {
    if (ctx.embed) {
      setEditingGamePack(options.pack);
    } else {
      openGamePackEditor({
        type: 'edit',
        version: options.pack.version,
        packId: options.pack.id,
      });
    }
  };

  const onDuplicateGamePack = async () => {
    if (options.pack.isPrime) {
      setDuplicatingGamePack(options.pack);
    } else {
      const pack = await duplcateGamePack(options.pack.id);
      if (!pack) return;
      emitter.emit('duplicated', pack);
      setDuplicatingGamePack(null);
    }
  };

  const onDeleteGamePack = () => {
    setDeletingGamePack(options.pack);
  };

  const onPublishGamePack = () => {
    setPublishingGamePack(options.pack);
  };

  const onAddToMyGamePacks = async () => {
    const pack = await forkGamePack(options.pack.id);
    if (!pack) return;
    emitter.emit('duplicated', pack);
    notificationDataSource.send({
      id: uuidv4(),
      toUserClientId: '',
      type: NotificationType.GamePackAction,
      createdAt: Date.now(),
      metadata: {
        actionType: GamePackActionType.CopyPrimeGamePack,
        gamePack: pack,
      },
    });
  };

  const onToggleFeatured = async (featured: boolean) => {
    if (featured) {
      triggerPromotionalAssetsModal({
        pack: options.pack,
        confirmText: 'Save and Feature',
        onConfirm: async (promotionalAssets) => {
          const resp = await apiService.gamePack.update(options.pack.id, {
            featured,
            promotionalAssets:
              toGamePackPromotionalAssetsDTO(promotionalAssets),
            changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
          });
          emitter.emit('updated', fromDTOGamePack(resp.data.gamePack));
        },
      });
    } else {
      const resp = await apiService.gamePack.update(options.pack.id, {
        featured,
        changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      });
      emitter.emit('updated', fromDTOGamePack(resp.data.gamePack));
    }
  };

  const onEditPromotionalAssets = () => {
    triggerPromotionalAssetsModal({
      pack: options.pack,
      confirmText: 'Save',
      onConfirm: async (promotionalAssets) => {
        const resp = await apiService.gamePack.update(options.pack.id, {
          promotionalAssets: toGamePackPromotionalAssetsDTO(promotionalAssets),
          changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
        });
        emitter.emit('updated', fromDTOGamePack(resp.data.gamePack));
      },
    });
  };

  const onPreview = () => {
    setPreviewGamePack(options.pack);
  };

  const onExport = async () => {
    const resp = await apiService.gamePack.getGamePackById(options.pack.id, {
      blocks: true,
      brands: true,
      games: true,
    });
    downloadObjectAsJSON(resp.data, `${options.pack.name}_${Date.now()}`);
  };

  const [onCopyPublicLink, copyPublicLinkLabel] = useCopyTextLink({
    label: <>Copy: Public Link</>,
    getter() {
      const url = new SiteBaseURL();
      url.pathname = `/games/${slugify(options.pack.name)}/${options.pack.id}`;
      return url.toString();
    },
  });

  const [onCopyEditLink, copyEditLinkLabel] = useCopyTextLink({
    label: <>Copy: Admin Edit Link</>,
    getter: () => {
      const url = new SiteBaseURL();
      url.pathname = `/admin/gamepacks/edit/${options.pack.id}`;
      return url.toString();
    },
  });

  const [onCopyVenueLink, copyVenueLinkLabel] = useCopyTextLink({
    label: <>Copy: Load in My Venue Link</>,
    getter() {
      const url = new SiteBaseURL();
      url.pathname = `/my-venue/${options.pack.id}`;
      return url.toString();
    },
  });

  const triggerTrainingShareModal = useTrainingShareModal();
  const onCraftSlidesShareLink = () => {
    triggerTrainingShareModal(options.pack.id);
  };

  const held = useMetaKeyHeld();
  const playtestUrl = GamePackUtils.GetPlaytestUrl(options.pack, held);
  const cohostUrl = GamePackUtils.GetCohostUrl(options.pack, held);

  const v1 = options.pack.version === EnumsGamePackVersion.GamePackVersionV1;

  const makeMenuItemClick = (...actions: (() => void)[]) => {
    return () => {
      for (const action of actions) {
        action();
      }
    };
  };

  return (
    <CustomizableGamePackActionSheet {...props}>
      {(onItemClick) => (
        <>
          {options.settings?.edit && (
            <button
              type='button'
              onClick={makeMenuItemClick(onEditingGamePack, onItemClick)}
              className={itemMenuStyle}
            >
              <EditIcon />
              <div className=''>
                Edit {options.pack.isPrime && 'Prime'} Game Pack
              </div>
            </button>
          )}
          {options.settings?.duplicate && (
            <button
              type='button'
              onClick={makeMenuItemClick(onDuplicateGamePack, onItemClick)}
              className={itemMenuStyle}
            >
              {duplcateGamePackState.isRunning && (
                <Loading
                  imgClassName='w-3.5 w-3.5'
                  text=''
                  containerClassName='mr-2'
                />
              )}
              <DuplicateIcon />
              <div className=''>Duplicate</div>
            </button>
          )}
          {options.settings?.publish && isAdmin && v1 && (
            <button
              type='button'
              onClick={makeMenuItemClick(onPublishGamePack, onItemClick)}
              className={itemMenuStyle}
            >
              <ShareIcon />
              <div className=''>Publish to Library</div>
            </button>
          )}
          {options.settings?.addToMySpace && options.pack.isPrime && v1 && (
            <button
              type='button'
              onClick={makeMenuItemClick(onAddToMyGamePacks, onItemClick)}
              className={itemMenuStyle}
            >
              {forkGamePackState.isRunning && (
                <Loading
                  imgClassName='w-3.5 w-3.5'
                  text=''
                  containerClassName='mr-2'
                />
              )}
              <DownloadIcon className='w-3.5 h-3.5 fill-current' />
              <div className=''>Add to My Game Packs</div>
            </button>
          )}
          {options.settings?.feature && (
            <button
              type='button'
              onClick={makeMenuItemClick(onItemClick, () =>
                onToggleFeatured(!props.pack.isFeatured)
              )}
              className={itemMenuStyle}
            >
              <DuplicateIcon />
              <div className=''>
                {props.pack.isFeatured
                  ? 'Unfeature On Demand'
                  : 'Feature On Demand'}
              </div>
            </button>
          )}
          {options.settings?.editPromotionalAssets && (
            <button
              type='button'
              onClick={makeMenuItemClick(onEditPromotionalAssets, onItemClick)}
              className={`${itemMenuStyle}`}
            >
              <EditIcon />
              <div className=''>Edit Promotional Assets</div>
            </button>
          )}
          {options.settings?.delete && (
            <button
              type='button'
              onClick={makeMenuItemClick(onDeleteGamePack, onItemClick)}
              className={`${itemMenuStyle} text-red-002`}
            >
              <DeleteIcon />
              <div className=''>
                Delete {options.pack.isPrime && 'Prime'} Game Pack
              </div>
            </button>
          )}
          {v1 && (
            <button
              type='button'
              onClick={makeMenuItemClick(onPreview, onItemClick)}
              className={`${itemMenuStyle}`}
            >
              <EyeIcon />
              <div className=''>Preview</div>
            </button>
          )}
          {options.settings?.export && (
            <button
              type='button'
              onClick={makeMenuItemClick(onExport, onItemClick)}
              className={`${itemMenuStyle}`}
            >
              <DownloadIcon className='w-3.5 h-3.5 fill-current' />
              <div className=''>Export (to JSON)</div>
            </button>
          )}
          {options.settings?.copyPublicLink && (
            <button
              type='button'
              onClick={() => onCopyPublicLink(onItemClick)}
              className={`${itemMenuStyle}`}
            >
              <CopyIcon className='w-3.5 h-3.5 fill-current' />
              <div className='relative'>{copyPublicLinkLabel}</div>
            </button>
          )}
          {options.settings?.copyEditLink && (
            <button
              type='button'
              onClick={() => onCopyEditLink(onItemClick)}
              className={`${itemMenuStyle}`}
            >
              <CopyIcon className='w-3.5 h-3.5 fill-current' />
              <div className='relative'>{copyEditLinkLabel}</div>
            </button>
          )}
          {options.settings?.copyMyVenueLink && (
            <button
              type='button'
              onClick={() => onCopyVenueLink(onItemClick)}
              className={`${itemMenuStyle}`}
            >
              <CopyIcon className='w-3.5 h-3.5 fill-current' />
              <div className='relative'>{copyVenueLinkLabel}</div>
            </button>
          )}
          {options.settings?.testGamePack && (
            <Link to={playtestUrl}>
              <button type='button' className={`${itemMenuStyle}`}>
                {held ? (
                  <ToolsIcon className='w-3.5 h-3.5 fill-current' />
                ) : (
                  <PlayIcon className='w-3.5 h-3.5 fill-current' />
                )}
                <div className='relative'>Playtest OnD Game Pack</div>
              </button>
            </Link>
          )}
          {options.settings?.cohostGamePack && (
            <Link to={cohostUrl}>
              <button type='button' className={`${itemMenuStyle}`}>
                {held ? (
                  <ToolsIcon className='w-3.5 h-3.5 fill-current' />
                ) : (
                  <HostIcon className='w-3.5 h-3.5 fill-current' />
                )}
                <div className='relative'>Cohost OnD Game Pack</div>
              </button>
            </Link>
          )}
          {options.settings?.editSlides && (
            <Link to={$path('/trainings/:id/edit', { id: options.pack.id })}>
              <button type='button' className={`${itemMenuStyle}`}>
                <EditIcon />
                <div className='relative'>Edit Slides</div>
              </button>
            </Link>
          )}
          {options.settings?.craftSlidesShareLink && (
            <button
              type='button'
              onClick={makeMenuItemClick(onCraftSlidesShareLink, onItemClick)}
              className={`${itemMenuStyle}`}
            >
              <ShareIcon className='w-3.5 h-3.5 fill-current' />
              <div className='relative'>Craft Slides Share Link</div>
            </button>
          )}
          {options.settings?.transferOwnership && (
            <button
              type='button'
              onClick={makeMenuItemClick(
                () =>
                  triggerTransferOwnershipModal(options.pack, () =>
                    emitter.emit('deleted', options.pack)
                  ),
                onItemClick
              )}
              className={itemMenuStyle}
            >
              <ExitIcon />
              <div className=''>Transfer Ownership</div>
            </button>
          )}
          <div className='w-full h-8 px-2 text-3xs text-icon-gray flex items-center gap-2'>
            <CalendarIcon className='w-3.5 h-3.5 fill-current' />
            <span title='Created At'>
              {DateUtils.FormattedMMDDYYYY(props.pack.createdAt)}
            </span>
            {' | '}
            <span title='Updated At'>
              {DateUtils.FormattedMMDDYYYY(props.pack.updatedAt)}
            </span>
          </div>
        </>
      )}
    </CustomizableGamePackActionSheet>
  );
};

export function CustomizableGamePackActionSheet(
  props: GamePackActionSheetProps & {
    children?: (onItemClick: () => void) => ReactNode;
  }
) {
  const options = Object.assign({}, { stopPropagation: false }, props);
  const [showItemMenu, setShowItemMenu] = useState(false);

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'click',
      placement: 'auto',
      closeOnTriggerHidden: true,
      visible: showItemMenu,
      onVisibleChange: setShowItemMenu,
    });

  const onContainerClick = (e: React.MouseEvent) => {
    if (options.stopPropagation) e.stopPropagation();
  };

  const onClick = (event: React.MouseEvent) => {
    if (options.stopPropagation) event.stopPropagation();
    if (options.onClick) options.onClick();
    setShowItemMenu(!showItemMenu);
  };

  const hideMenu = () => setShowItemMenu(false);

  return (
    <div onClick={onContainerClick}>
      <button
        className={`${
          options.buttonClassName || ''
        } btn flex flex-row justify-center items-center text-white bg-black 
        rounded-lg border border-solid border-secondary hover:bg-light-gray ${
          props.disabled ? 'cursor-not-allowed pointer-events-on' : ''
        }`}
        onMouseEnter={options.onMouseEnter}
        onMouseLeave={options.onMouseLeave}
        onClick={onClick}
        ref={setTriggerRef}
        type='button'
        disabled={props.disabled}
      >
        <OptionsIcon />
      </button>
      {visible && (
        <ActionSheetTooltip portal={props.portal}>
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className:
                'w-auto h-auto tooltip-auto-hide border border-[#303436] rounded-lg text-white flex flex-col flex-wrap p-1 transition-opacity bg-black whitespace-nowrap',
            })}
          >
            {props.children?.(hideMenu)}
          </div>
        </ActionSheetTooltip>
      )}
    </div>
  );
}

export function GamePackActionSheetButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  const { className, ...rest } = props;
  return (
    <button
      type='button'
      className={`${itemMenuStyle} ${className}`}
      {...rest}
    />
  );
}
