import { type Block, BlockType } from '@lp-lib/game';

import { assertExhaustive } from '../../../../../utils/common';
import { AIChatBlockEditor } from '../../AIChat';
import { CreativePromptBlockEditor } from '../../CreativePrompt';
import { DrawingPromptBlockEditor } from '../../DrawingPrompt';
import { GuessWhoBlockEditor } from '../../GuessWho/GuessWhoBlockEditor';
import { HeadToHeadBlockEditor } from '../../HeadToHead';
import { HiddenPictureBlockEditor } from '../../HiddenPicture';
import { IcebreakerBlockEditor } from '../../Icebreaker';
import { InstructionBlockEditor } from '../../Instruction';
import { JeopardyBlockEditor } from '../../Jeopardy/JeopardyBlockEditor';
import { MarketingBlockEditor } from '../../Marketing/MarketingBlockEditor';
import { MemoryMatchBlockEditor } from '../../MemoryMatch';
import { MultipleChoiceBlockEditor } from '../../MultipleChoice';
import { OverRoastedBlockEditor } from '../../OverRoasted';
import { PuzzleBlockEditor } from '../../Puzzle';
import { QuestionBlockEditor } from '../../Question';
import { RandomizerBlockEditor } from '../../Randomizer';
import { RapidBlockEditor } from '../../Rapid';
import { RoundRobinQuestionBlockEditor } from '../../RoundRobinQuestion';
import { ScoreboardBlockEditor } from '../../Scoreboard';
import { SpotlightBlockEditor } from '../../Spotlight/SpotlightBlockEditor';
import { SpotlightBlockV2Editor } from '../../SpotlightV2';
import { TeamRelayBlockEditor } from '../../TeamRelay';
import { TitleBlockV2Editor } from '../../TitleV2';
import { type EditorProps } from './EditorUtilities';

export function BlockEditor(
  props: Omit<EditorProps<Block>, 'block'> & { block: Block | null }
): JSX.Element | null {
  const { block, ...restProps } = props;

  if (!block) return null;

  const blockType = block.type;

  switch (blockType) {
    case BlockType.QUESTION:
      return (
        <QuestionBlockEditor key={block.id} block={block} {...restProps} />
      );
    case BlockType.CREATIVE_PROMPT:
      return (
        <CreativePromptBlockEditor
          key={block.id}
          block={block}
          {...restProps}
        />
      );
    case BlockType.RAPID:
      return <RapidBlockEditor block={block} {...restProps} />;
    case BlockType.SCOREBOARD:
      return <ScoreboardBlockEditor block={block} {...restProps} />;
    case BlockType.SPOTLIGHT:
      return <SpotlightBlockEditor block={block} {...restProps} />;
    case BlockType.SPOTLIGHT_V2:
      return <SpotlightBlockV2Editor block={block} {...restProps} />;
    case BlockType.TEAM_RELAY:
      return <TeamRelayBlockEditor block={block} {...restProps} />;
    case BlockType.RANDOMIZER:
      return <RandomizerBlockEditor block={block} {...restProps} />;
    case BlockType.MULTIPLE_CHOICE:
      return <MultipleChoiceBlockEditor block={block} {...restProps} />;
    case BlockType.MEMORY_MATCH:
      return <MemoryMatchBlockEditor block={block} {...restProps} />;
    case BlockType.PUZZLE:
      return <PuzzleBlockEditor block={block} {...restProps} />;
    case BlockType.ROUND_ROBIN_QUESTION:
      return (
        <RoundRobinQuestionBlockEditor
          key={block.id}
          block={block}
          {...restProps}
        />
      );
    case BlockType.TITLE_V2:
      return <TitleBlockV2Editor key={block.id} block={block} {...restProps} />;
    case BlockType.INSTRUCTION:
      return (
        <InstructionBlockEditor key={block.id} block={block} {...restProps} />
      );
    case BlockType.OVERROASTED:
      return (
        <OverRoastedBlockEditor key={block.id} block={block} {...restProps} />
      );
    case BlockType.DRAWING_PROMPT:
      return (
        <DrawingPromptBlockEditor key={block.id} block={block} {...restProps} />
      );
    case BlockType.HIDDEN_PICTURE:
      return (
        <HiddenPictureBlockEditor key={block.id} block={block} {...restProps} />
      );
    case BlockType.AI_CHAT:
      return <AIChatBlockEditor key={block.id} block={block} {...restProps} />;
    case BlockType.GUESS_WHO:
      return (
        <GuessWhoBlockEditor key={block.id} block={block} {...restProps} />
      );
    case BlockType.ICEBREAKER:
      return (
        <IcebreakerBlockEditor key={block.id} block={block} {...restProps} />
      );
    case BlockType.MARKETING:
      return (
        <MarketingBlockEditor key={block.id} block={block} {...restProps} />
      );
    case BlockType.JEOPARDY:
      return (
        <JeopardyBlockEditor key={block.id} block={block} {...restProps} />
      );
    case BlockType.HEAD_TO_HEAD:
      return (
        <HeadToHeadBlockEditor key={block.id} block={block} {...restProps} />
      );
    case BlockType.SLIDE:
    case BlockType.CODE_CANVAS:
    case BlockType.DRAW_TO_WIN:
    case BlockType.ROLEPLAY:
    case BlockType.SPARKIFACT:
    case BlockType.FILL_IN_THE_BLANKS:
    case BlockType.SWIPE_TO_WIN:
    case BlockType.SCENARIO:
    case BlockType.RESULTS:
      return null;
    default:
      assertExhaustive(blockType);
      return null;
  }
}
