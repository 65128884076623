import { useNavigate } from '@remix-run/react';
import { useRef, useState } from 'react';
import { $path } from 'remix-routes';

import {
  EnumsGamePackVersion,
  EnumsPageName,
} from '@lp-lib/api-service-client/public';
import { BlockType } from '@lp-lib/game';

import { useLearningAnalytics } from '../../../../analytics/learning';
import { useFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { getLogger } from '../../../../logger/logger';
import { apiService } from '../../../../services/api-service';
import { useTriggerGamePackPicker } from '../../../Game/GamePackPicker';
import { AIIcon } from '../../../icons/AIIcon';
import { DocumentIcon } from '../../../icons/DocumentIcon';
import { TemplateIcon } from '../../../icons/TemplateIcon';

const logger = getLogger().scoped('create-course');

/**
 * Button to create a new course with options for AI assistance, templates, or starting from scratch.
 * @param backTo - Optional URL to return to after creating a course with AI. Only pass this when
 *                the button is used in admin contexts where you want to return to a specific page.
 */
export function CreateCourseButton({
  backTo,
  trackEvent = true,
  useLunaGradient = true,
}: {
  backTo?: string;
  trackEvent?: boolean;
  useLunaGradient?: boolean;
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [navigating, setNavigating] = useState(false);
  const analytics = useLearningAnalytics();

  useOutsideClick(ref, () => setOpen(false));

  const handleCreateNewCourse = useLiveCallback(async () => {
    analytics.trackCreateCourseEmptyButtonClicked({});
    if (navigating) return;
    setNavigating(true);

    // this is best effort. if it fails, we'll still try to create the gamepack.
    let maybeMinigameId;
    try {
      const minigame = await apiService.game.create({
        name: 'Untitled Section',
      });
      maybeMinigameId = minigame.data.game.id;

      await apiService.block.createGameBlock(minigame.data.game.id, {
        position: 0,
        type: BlockType.SLIDE,
      });
    } catch (e) {
      logger.error('failed to create minigame/block for new course', e);
    }

    try {
      const res = await apiService.gamePack.create({
        version: EnumsGamePackVersion.GamePackVersionV1,
        childrenIds: maybeMinigameId ? [maybeMinigameId] : [],
        name: 'Untitled Course',
      });
      navigate($path('/trainings/:id/edit', { id: res.data.gamePack.id }));
    } finally {
      setNavigating(false);
    }
  });

  const handleAICourseBuilder = useLiveCallback(async () => {
    if (trackEvent) {
      analytics.trackCreateCourseAIButtonClicked({});
    }
    navigate($path('/learning/assistant', backTo ? { 'back-to': backTo } : {}));
  });

  const triggerGamePackPicker = useTriggerGamePackPicker();
  const handleTemplate = useLiveCallback(() => {
    if (trackEvent) {
      analytics.trackCreateCourseTemplateButtonClicked({});
    }
    triggerGamePackPicker({
      pageName: EnumsPageName.PageNameGeneralLearning,
    });
  });

  const handleButtonClick = useLiveCallback(() => {
    if (trackEvent) {
      analytics.trackCreateCourseAIButtonClicked({});
    }
    setOpen(!open);
  });

  const showLearningLibrary = useFeatureQueryParam('learning-library');

  const gradientClass = useLunaGradient
    ? 'bg-gradient-to-r from-luna-primary to-luna-secondary'
    : 'bg-gradient-to-tr from-[#FE0653] to-[#0029FF]';

  return (
    <div
      ref={ref}
      className={`flex align-center relative p-[1px] rounded-xl ${gradientClass} h-10`}
    >
      <button
        type='button'
        className='flex items-center gap-2 bg-[#232325] text-white font-bold py-3 px-6 rounded-xl hover:bg-lp-gray-002 transition'
        onClick={handleButtonClick}
      >
        <AIIcon className='w-4 h-4 fill-current text-white' />
        <span>Create Course</span>
      </button>
      {open && (
        <div className='flex flex-col absolute -bottom-2 right-0 border border-secondary rounded-lg z-45 bg-black w-67 transform translate-y-full p-1'>
          <CreateCourseOption
            icon={<AIIcon className='w-7.5 h-7.5 fill-luna-primary' />}
            title='AI Course Builder'
            subtitle='Generate your course by uploading files or use AI to create engaging content'
            onClick={handleAICourseBuilder}
          />

          {showLearningLibrary && (
            <CreateCourseOption
              icon={<TemplateIcon className='w-7.5 h-7.5 fill-[#FBB707]' />}
              title='Template'
              subtitle='Start with a template, then customize it to your needs'
              onClick={handleTemplate}
            />
          )}

          <CreateCourseOption
            icon={<DocumentIcon className='w-7.5 h-7.5 fill-[#00D0C4]' />}
            title='Empty Course'
            subtitle='Build a new course from scratch'
            onClick={handleCreateNewCourse}
          />
        </div>
      )}
    </div>
  );
}

function CreateCourseOption(props: {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  onClick: () => void;
}) {
  return (
    <button
      type='button'
      className='w-full p-4 hover:bg-secondary flex gap-4 rounded-md cursor-pointer transition-colors text-left'
      onClick={props.onClick}
    >
      <div className='flex-none'>{props.icon}</div>
      <div className='flex-1'>
        <div className='font-bold text-sms text-white'>{props.title}</div>
        <div className='font-medium text-3xs text-icon-gray'>
          {props.subtitle}
        </div>
      </div>
    </button>
  );
}
